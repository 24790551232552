import React, { useRef, useEffect } from 'react';
import { TimelineLite } from 'gsap';

import Layout from './layout';
import styles from './subpage.module.css';

const SubPage = (props) => {
    let title = useRef(null);
    let content = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite;

        tl.set(title, {
            opacity: 0,
            y:10
        })
        .to(title, 0.5, {
            y: 0,
            opacity: 1,
        })
        .to(content, 0.4, {
            opacity: 1
        }, '-=0.4')
    }, [])


    return (
        <Layout subpage={true}>
            <div className={styles.container}>
                <h2 className={styles.title} ref={el => {title = el}}>{props.title}</h2>
                <div className={styles.content} ref={el => {content = el}}>
                    {props.children}
                </div>
            </div>
        </Layout>
    )
}

export default SubPage;

import React from "react";
import { Helmet } from "react-helmet"
import PropTypes from "prop-types";

import styles from "./layout.module.css";
import Footer from "./footer";

const Layout = (props) => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Dave Howson</title>
            </Helmet>
            <div className={styles.container}>
                {props.children}
                <Footer subpage={props.subpage}/>
            </div>
        </React.Fragment>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

import React from 'react';

import SubPage from "../components/subpage";
import styles from './about.module.css';

const AboutPage = () => {
    return (
        <SubPage title='about'>
            <div className={styles.about}>
                <p className={styles.text}>
                    I am a web developer from Sri Lanka who is very passionate about building websites and web applications.
                    I recently completed my Bachelor&apos;s Degree in Information Technology and is currently looking for
                    opportunities to work in the field of Web Development. I have been working as a freelance web developer
                    for a few years and I&apos;ve developed a handful of websites and web applications using various technologies.
                    I code mostly using JavaScript, Java, PHP and Python, and I use frameworks such as React, Laravel, Flask and Spring Boot.
                    I am always eager to learn new technologies and I would love to hear from you if you have any exciting work for me.
                </p>
            </div>
        </SubPage>
    )
}

export default AboutPage;

import React from "react";
import { Link } from "gatsby";
import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";

import styles from "./footer.module.css";

const Footer = (props) => {
    let homeLink;

    if (props.subpage) {
        homeLink = <li id="about"><Link to="/" activeClassName={styles.active}>home</Link></li>
    }


    return (
        <React.Fragment>
            <div className={styles.nav} >
                <ul className={styles.navList}>
                    {homeLink}
                    <li id="about"><Link to="/about" activeClassName={styles.active}>about</Link></li>
                    <li id="work"><Link to="/work" activeClassName={styles.active}>work</Link></li>
                    <li id="contact"><Link to="/contact" activeClassName={styles.active}>contact</Link></li>
                </ul>
            </div>
            <div className={styles.social}>
                <ul className={styles.socialList}>
                    <li id="github"><a href="https://github.com/davehowson" target="_blank" rel="noopener noreferrer"><FaGithub /></a></li>
                    <li id="twitter"><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                    <li id="linkedin"><a href="https://www.linkedin.com/in/dave-howson/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default Footer
